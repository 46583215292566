import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  CustomCSSProperties,
  DashboardMSTypes,
  ExtractPropertyFromArray,
} from '../../../types/definitions';
import { ReactNode } from 'react';
import styles from './AlertsChart.module.css';

type AlertChartProps = {
  data: DashboardMSTypes.Alerts;
};

function AlertsChart(props: AlertChartProps) {
  const { data }: AlertChartProps = props;

  type DataNames = ExtractPropertyFromArray<
    (typeof data)[number],
    'alert'
  >['description'];

  const colors = {
    'Inconsistência compliance ambiental': '#64DD17',
    'Área de plantio menor': '#FFD54F',
    'Área informada divergente': '#00B8D4',
    'Plantio não realizado': '#F44336',
    'Colheita não realizada': '#7C4DFF',
    'Plantio atrasado zoneado': '#009688',
    'Plantio atrasado não zoneado': '#74A6FD',
  } as const satisfies { [key in DataNames]: string };

  function selectColor(name: DataNames): string | undefined {
    return colors[name] || undefined;
  }

  function generateLegend(): ReactNode {
    const legend = data.map((datum, key) => {
      const color = colors[datum.alert.description] || undefined;
      return (
        <li
          className={styles.item}
          key={key}
          style={{ '--color': color } as CustomCSSProperties}
        >
          {datum.alert.description}
        </li>
      );
    });

    return <ul className={styles.legend}>{legend}</ul>;
  }

  interface CustomTooltipProps {
    active?: boolean;
    payload?: any;
  }

  const CustomTooltip = ({ active, payload }: CustomTooltipProps) => {
    if (!active || !payload || !payload.length) return null;

    let { count, alert } = payload[0].payload;
    let decription = alert.description;

    return (
      <div className={styles.containerTooltip}>
        <p>
          Alerta: <b style={{ color: selectColor(decription) }}>{decription}</b>
        </p>
        <p>
          Quantidade: <b>{count}</b>
        </p>
      </div>
    );
  };

  return (
    <ResponsiveContainer width={'100%'} minWidth={800} height={600}>
      <BarChart
        width={500}
        height={600}
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        data={data}
      >
        <CartesianGrid strokeDasharray={'3 3'} />
        <XAxis hide />
        <YAxis type="number" domain={[0, 12000]} />
        <Legend content={generateLegend} />
        <Tooltip content={CustomTooltip} />
        <Bar dataKey={'count'} fill="#82ca9d">
          {data.map((datum, key) => {
            return (
              <Cell
                key={key}
                fill={selectColor(datum.alert.description)}
                width={80}
                radius={4}
              />
            );
          })}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}

export default AlertsChart;
