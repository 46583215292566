import React from 'react';
import { ComponentPropsWithRef, ChangeEvent, ChangeEventHandler } from 'react';
import styles from './TextAreaInput.module.css';

type TextAreaInputProps = {
  label: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  className?: string;
  textArea?: Omit<ComponentPropsWithRef<'textarea'>, 'onChange' | 'maxLength'>;
};

function TextAreaInput(props: TextAreaInputProps) {
  const { label, onChange, className, textArea } = props;

  function onChangeWrapper(e: ChangeEvent<HTMLTextAreaElement>) {
    onChange && onChange(e);
  }

  return (
    <>
      <textarea
        className={`${styles.textarea} ${className}`}
        placeholder={label}
        onChange={onChangeWrapper}
        {...textArea}
      />
    </>
  );
}

export default TextAreaInput;
