import React from 'react';
import { OperationMSTypes } from '../../../types/definitions';
import { LayerGroup, LayersControl, GeoJSON } from 'react-leaflet';
import toFeatureCollection from '../../../utils/geo/toFeatureCollection.util';
import { MultiPolygon } from 'geojson';
import withErrorBoundary from '../../boundaries/operations/withErrorBoundary.hook';
import ErrorFallbackNDVI from '../../boundaries/operations/ErrorFallbackNDVI';

type GlebeOverlayProps = {
  data: OperationMSTypes.ById['glebes'][number];
};

function GlebeOverlay(props: GlebeOverlayProps) {
  const { data }: GlebeOverlayProps = props;

  const geometry = data?.geom;
  const details = data?.compliance?.details;

  const differences = toFeatureCollection(
    details.flatMap((detail) => detail.geojson_difference)
  );
  const preservations = details.flatMap((detail) => detail.geojson);
  const planting_area = data.geom_planting;

  const groupedPreservations = preservations.reduce((acc, curr) => {
    const name = curr.properties.name;

    if (!acc[name]) {
      acc[name] = [curr];
    } else {
      acc[name].push(curr);
    }

    return acc;
  }, {} as Record<string, GeoJSON.Feature<MultiPolygon, { id: string; name: string; description: string; type_area: string; year: number; color: string }>[]>);

  return (
    <React.Fragment>
      <LayersControl.Overlay
        checked
        name="<span style='color: dodgerblue'>Gleba</span>"
      >
        <LayerGroup>
          {geometry && (
            <GeoJSON
              data={geometry}
              style={{ color: 'dodgerblue', weight: 2 }}
            />
          )}
        </LayerGroup>
      </LayersControl.Overlay>
      {groupedPreservations &&
        Object.keys(groupedPreservations).map((name, index) => {
          const featureCollection = toFeatureCollection(
            groupedPreservations[name]
          );
          const color =
            groupedPreservations[name][0].properties.color || 'black';

          return (
            <LayersControl.Overlay
              key={index}
              checked
              name={`<span style='color: ${color}'>${name}</span>`}
            >
              <LayerGroup>
                {featureCollection && (
                  <GeoJSON
                    data={featureCollection}
                    style={{ color: color, fillOpacity: 0.8, weight: 2 }}
                  />
                )}
              </LayerGroup>
            </LayersControl.Overlay>
          );
        })}
      <LayersControl.Overlay
        checked
        name="<span style='color: black'>Área agricultável</span>"
      >
        <LayerGroup>
          {planting_area && (
            <GeoJSON
              data={planting_area as any}
              style={{ color: 'black', fillOpacity: 0.1, weight: 2 }}
            />
          )}
        </LayerGroup>
      </LayersControl.Overlay>
      <LayersControl.Overlay
        checked
        name="<span style='color: red'>Área sobreposta</span>"
      >
        <LayerGroup>
          {differences && (
            <GeoJSON
              data={differences}
              style={{ color: 'red', fillOpacity: 0.9, weight: 2 }}
            />
          )}
        </LayerGroup>
      </LayersControl.Overlay>
    </React.Fragment>
  );
}

export default withErrorBoundary(GlebeOverlay, {
  fallback: ErrorFallbackNDVI,
});
