import { OperationMSTypes } from '../../types/definitions';
import HTTP from '../HTTP';

export default abstract class OperationMS {
  private static readonly ms_base_url: string = `${process.env
    .REACT_APP_OPERATION_MS!}/api/operations`;

  public static async All(filters?: URLSearchParams) {
    return await HTTP.GET<OperationMSTypes.All>(
      `${this.ms_base_url}?${filters}`
    );
  }

  public static async Alerts() {
    return await HTTP.GET<OperationMSTypes.Alerts>(
      `${this.ms_base_url}/alerts`
    );
  }

  public static async Culture() {
    return await HTTP.GET<OperationMSTypes.Culture>(
      `${this.ms_base_url}/culture`
    );
  }

  public static async Totals(filters?: URLSearchParams) {
    return await HTTP.GET<OperationMSTypes.Totals>(
      `${this.ms_base_url}/totals?${filters}`
    );
  }

  public static async ById(id: string) {
    return await HTTP.GET<OperationMSTypes.ById>(`${this.ms_base_url}/${id}`);
  }

  public static async Reprocess(id: string) {
    return await HTTP.DELETE<any>(`${this.ms_base_url}/${id}/monitoring`);
  }

  public static async Report(id: string) {
    return await HTTP.GETBlob(`${this.ms_base_url}/${id}/report`, {
      'Content-Type': 'application/pdf',
    });
  }

  public static async CounterByCooperative(
    cooperativeId: number | string,
    filters?: URLSearchParams
  ) {
    return await HTTP.GET<OperationMSTypes.CounterByCooperative>(
      `${this.ms_base_url}/${cooperativeId}/counter?${filters}`
    );
  }

  public static async ExportListOperationsCsv(
    cooperativeId: number | string,
    filters?: URLSearchParams
  ) {
    return await HTTP.GETBlob(
      `${this.ms_base_url}/${cooperativeId}/list/csv?${filters}`,
      {
        'Content-Type': 'application/csv',
      }
    );
  }

  public static async ExportCounterByCooperativeCsv(
    cooperativeId: number | string,
    filters?: URLSearchParams
  ) {
    return await HTTP.GETBlob(
      `${this.ms_base_url}/${cooperativeId}/counter/csv?${filters}`,
      {
        'Content-Type': 'application/csv',
      }
    );
  }
}
