import Cookies from 'js-cookie';

export default abstract class HTTP {
  private static readonly token: string =
    Cookies.get(String(process.env.REACT_APP_COOKIE_KEY_TOKEN)) || '';
  private static requestInit: RequestInit = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: HTTP.token,
    },
  };

  private static mergeHeaders(
    customHeaders: Record<string, string>
  ): RequestInit {
    return {
      ...this.requestInit,
      headers: {
        ...this.requestInit.headers,
        ...customHeaders,
      },
    };
  }

  public static async GET<TResponse = unknown>(
    endpoint: string,
    customHeaders?: Record<string, string>
  ): Promise<TResponse | false> {
    try {
      const response: Response = await fetch(endpoint, {
        method: 'GET',
        ...this.mergeHeaders(customHeaders || {}),
      });

      if (!response.ok)
        throw new Error('Failed to fetch. Response is not ok :(');

      return response.json();
    } catch (error: unknown) {
      return false;
    }
  }

  public static async GETBlob<TResponse = unknown>(
    endpoint: string,
    customHeaders?: Record<string, string>
  ): Promise<TResponse | Blob | false> {
    try {
      const response: Response = await fetch(endpoint, {
        method: 'GET',
        ...this.mergeHeaders(customHeaders || {}),
      });

      if (!response.ok)
        throw new Error('Failed to fetch. Response is not ok :(');

      return response.blob();
    } catch (error: unknown) {
      return false;
    }
  }

  public static async POST<TBody = unknown, TResponse = unknown>(
    endpoint: string,
    body: TBody,
    customHeaders?: Record<string, string>
  ): Promise<TResponse | false> {
    try {
      const response: Response = await fetch(endpoint, {
        method: 'POST',
        body: JSON.stringify(body),
        ...this.mergeHeaders(customHeaders || {}),
      });

      if (!response.ok)
        throw new Error('Failed to fetch. Response is not ok :(');

      return response.json();
    } catch (error: unknown) {
      return false;
    }
  }

  public static async PATCH<TBody = unknown, TResponse = unknown>(
    endpoint: string,
    body: TBody,
    customHeaders?: Record<string, string>,
    type: 'json' | 'form' = 'json'
  ): Promise<TResponse | false> {
    try {
      const response: Response = await fetch(endpoint, {
        method: 'PATCH',
        body:
          type === 'json'
            ? (JSON.stringify(body) as BodyInit)
            : (body as FormData),
        headers:
          type === 'json'
            ? this.mergeHeaders(customHeaders || {}).headers
            : {
                Accept: 'application/json',
                Authorization: HTTP.token,
              },
      });

      if (!response.ok)
        throw new Error('Failed to fetch. Response is not ok :(');

      return response.json();
    } catch (error: unknown) {
      return false;
    }
  }

  public static async DELETE<TResponse = unknown>(
    endpoint: string,
    customHeaders?: Record<string, string>
  ): Promise<TResponse | false> {
    try {
      const response: Response = await fetch(endpoint, {
        method: 'DELETE',
        ...this.mergeHeaders(customHeaders || {}),
      });

      if (!response.ok)
        throw new Error('Failed to fetch. Response is not ok :(');

      return response.json();
    } catch (error: unknown) {
      return false;
    }
  }
}
