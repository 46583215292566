import React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import styles from './LaudoAgricola.module.css';
import { useState } from 'react';
import cls from '../../../../../utils/cls.util';
import { Chart } from '../../../../../components/Charts';
import Icon from '../../../../../components/Icon/Icon.component';
import { OperationMSTypes } from '../../../../../types/definitions';
import toLocaleDate from '../../../../../utils/toLocaleDate.util';
import CustomMap from '../../../../../components/Map/Map.component';
import getBbox from '../../../../../utils/geo/getBbox.util';
import getCentroid from '../../../../../utils/geo/getCentroid.util';
import GlebeOverlay from '../../../../../components/Map/overlays/GlebeOverlay.overlay';

type LaudoAgricolaProps = {
  data: OperationMSTypes.ById;
};

function LaudoAgricola(props: LaudoAgricolaProps): JSX.Element {
  const { data }: LaudoAgricolaProps = props;
  const [tab, setTab] = useState<number>(1);

  const weather = data.glebes.find((glebe) => !!glebe.weather)?.weather;

  const columns = [
    { field: 'n', headerName: 'Nº', flex: 1, minWidth: 100 },
    { field: 'area', headerName: 'Área (ha)', flex: 1, minWidth: 100 },
    {
      field: 'zoning',
      headerName: 'Zoneamento Agrícola',
      flex: 1,
      minWidth: 200,
    },
    { field: 'planting', headerName: 'Plantio', flex: 1, minWidth: 300 },
    { field: 'crop', headerName: 'Cultura', flex: 1, minWidth: 300 },
  ] satisfies GridColDef[];
  return (
    <article className={styles.container}>
      <section className={styles.details}>
        <h3>
          <Icon name="Scan" />
          Produção
        </h3>
        <div className={styles.info}>
          <span className={styles.data}>
            <small>Previsão de plantio</small>
            <p>{toLocaleDate(data.planting_start)}</p>
          </span>
          <span className={styles.data}>
            <small>Previsão de colheita</small>
            <p>{toLocaleDate(data.crop_start)}</p>
          </span>
          <span className={styles.data}>
            <small>Produtividade média da região</small>
            <p>
              {data.production_average_region}{' '}
              {data.production_average_region_unity} / {data.area_type}
            </p>
          </span>
          <span className={styles.data}>
            <small>Tipo de solo</small>
            <p>{data.soil}</p>
          </span>
        </div>
      </section>
      <hr />
      <section className={styles.details}>
        <h3>
          <Icon name="FileText" />
          Análise para Manual de Crédito Rural (MCR)
        </h3>
        <div className={styles.info}>
          <span className={styles.data}>
            <small>Cultura</small>
            <p>{data.validated_culture || 'Não disponível'}</p>
          </span>
          <span className={styles.data}>
            <small>Precisão</small>
            <p>{data.precision}%</p>
          </span>
        </div>
      </section>
      <hr />
      <section className={styles.details}>
        <h3>
          <Icon name="Cloud" />
          Gráfico de clima
        </h3>
        <div>
          <Chart.Weather data={weather} />
        </div>
      </section>
      <hr />
      <section className={styles.details}>
        <h3>
          <Icon name="Projector" />
          Análise socioambiental
        </h3>
        <div className={styles.info}>
          <span className={styles.data}>
            <small>Área total financiada</small>
            <p>
              {data.financed_area} {data.area_type}
            </p>
          </span>
          <span className={styles.data}>
            <small>Área total informada</small>
            <p>
              {data.area} {data.area_type}
            </p>
          </span>
          <span className={styles.data}>
            <small>Área total de plantio</small>
            <p>
              {data.planting_area_total} {data.area_type}
            </p>
          </span>
        </div>
      </section>
      <hr />
      <section className={styles.details}>
        <h3>
          <Icon name="Stack" />
          Selecione uma gleba para visualizar
        </h3>
        <div className={styles.tabs}>
          {data.glebes.map((glebe) => (
            <button
              key={glebe.index}
              className={cls(
                tab === glebe.index,
                styles.tabButton,
                styles.selected
              )}
              onClick={() => setTab(glebe.index)}
            >
              Gleba {glebe?.index}
            </button>
          ))}
        </div>
        {data.glebes.map((glebe, index) => {
          if (tab !== glebe.index) return null;
          const chartData = glebe?.chart_ndvi?.data || [];
          const lastGlebe = Array.from(glebe?.chart_ndvi?.data || [])
            .reverse()
            .find((c) => c.y.filter(Boolean).length > 0);

          return (
            <div key={index} className={styles.gleba}>
              <section className={styles.details}>
                <h3>
                  <Icon name="Server" />
                  Dados
                </h3>
                <div className={styles.info}>
                  <span className={styles.data}>
                    <small>Área informada</small>
                    <p>{glebe.area}</p>
                  </span>
                  <span className={styles.data}>
                    <small>Área de plantio</small>
                    <p>{glebe.planting_area}</p>
                  </span>
                  <span className={styles.data}>
                    <small>Estágio vegetativo alcançado</small>
                    <p>
                      {chartData.length > 3
                        ? lastGlebe?.name?.split('(')[0]
                        : 'Não disponível'}
                    </p>
                  </span>
                  <span className={styles.data}>
                    <small>Coordenadas geodésicas</small>
                    <p>{glebe.geodetic_coordinate}</p>
                  </span>
                </div>
              </section>
              <section className={styles.glebaInfo}>
                <div className={styles.map}>
                  <CustomMap
                    center={getCentroid(glebe.geom)}
                    mapProps={{
                      className: styles.map,
                      maxBounds: getBbox(glebe.geom),
                    }}
                    overlays={[<GlebeOverlay data={glebe} />]}
                  />
                </div>
                <div className={styles.mapDataWrapper}>
                  <div className={styles.planting}>
                    {glebe.images.map((image, index) => {
                      return (
                        <div className={styles.plantingStage}>
                          <div className={styles.plantingImage}>
                            <img src={image.url} alt={image.text} />
                          </div>
                          <p>
                            <b>{image.text}</b>
                            <span>
                              {image.date === undefined
                                ? '-'
                                : new Date(image.date).toLocaleDateString(
                                    'pt-BR',
                                    {
                                      year: 'numeric',
                                      month: 'long',
                                      day: 'numeric',
                                    }
                                  )}
                            </span>
                          </p>
                        </div>
                      );
                    })}
                  </div>
                  <span className={styles.gradientMeter}></span>
                </div>
              </section>
              <section className={styles.details}>
                <h3>
                  <Icon name="Polygon" />
                  Polígono
                </h3>
                <p className={styles.polygon}>
                  {glebe.coordinates_area
                    .map((coord) => [`[Lat: ${coord.lat} Lon: ${coord.lng}]`])
                    .join(', ')}
                </p>
              </section>
              <section className={styles.details}>
                <h3>
                  <Icon name="ChartLine" />
                  Gráfico NDVI
                </h3>
                <div className={styles.chart}>
                  <Chart.NDVI
                    glebe={glebe}
                    planting={{
                      start: data.planting_start,
                      end: data.planting_end,
                    }}
                    crop={{ start: data.crop_start, end: data.crop_end }}
                  />
                </div>
              </section>
            </div>
          );
        })}
      </section>
      <hr />
      <section className={styles.details}>
        <h3>
          <Icon name="Map" />
          Talhões
        </h3>
        <div className={styles.info}>
          <DataGrid
            initialState={{ pagination: { paginationModel: { pageSize: 25 } } }}
            pageSizeOptions={[25]}
            getRowId={(row) => row.n}
            columns={columns}
            rows={[
              {
                n: data.code,
                area: `${Number(data.area.replace(',', '.')).toFixed(2)} ${
                  data.area_type
                }`,
                zoning: data.zoning,
                planting: `${toLocaleDate(
                  data.planting_start
                )} à ${toLocaleDate(data.planting_end)}`,
                crop: `${toLocaleDate(data.crop_start)} à ${toLocaleDate(
                  data.crop_end
                )}`,
              },
            ]}
            sx={{ '& .MuiDataGrid-main': { width: 0, minWidth: '100%' } }}
            disableColumnResize
            disableColumnMenu
            autoHeight
          />
        </div>
      </section>
      <section className={styles.details}>
        <h4>Responsabilidade de Análise</h4>
        <p>
          Responsável pela análise: <b>SpotSat LTDA</b>. Interpretação e
          Elaboração de Conclusões: <b>SpotView</b>. Responsável pelo
          Sensoriamento Remoto: <b>SpotSat LTDA</b>. Instituição Financeira e
          Responsável: <b>{data.company.name}</b>
        </p>
      </section>
      <section className={styles.details}>
        <h4>Dados Técnicos</h4>
        <p>
          Satélite <b>Sentinel 2</b> | Sensor <b>MSI</b> | Coleta de{' '}
          <b>17/03/2023 à 02/03/2024</b> | Resolução espacial de <b>10m</b> |
          Resolução radiométrica de <b>12bits/pixel</b> | Bandas utilizadas:{' '}
          <b>B08, B04</b> | Pré-processamento com método correção <b>TOA</b> |
          Processamento das imagens: <b>NDVI e EVI</b>
        </p>
      </section>
    </article>
  );
}

export default LaudoAgricola;
