import React from 'react';
import {
  ComponentPropsWithRef,
  MouseEvent,
  MouseEventHandler,
  useState,
} from 'react';
import styles from './Selector.module.css';
import Icon from './../Icon/Icon.component';

type SelectorProps = {
  items: string[];
  label: string;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onChange?: MouseEventHandler<HTMLButtonElement>;
  button?: Omit<ComponentPropsWithRef<'button'>, 'onClick' | 'value'>;
};

function Selector(props: SelectorProps) {
  const { items, onClick, onChange, className, label, button } = props;

  const [selected, setSelected] = useState<string | undefined>(undefined);
  const [expanded, setExpanded] = useState<boolean>(false);

  function onClickWrapper(e: MouseEvent<HTMLButtonElement>, item: string) {
    setSelected(item);
    setExpanded(false);
    onClick && onClick(e);
  }

  return (
    <div className={`${styles.wrapper} ${className}`} role="listbox">
      <button
        className={styles.input}
        onClick={() => setExpanded((prev) => !prev)}
        value={selected}
        {...button}
        onChange={onChange}
      >
        <p>{!selected ? label : selected}</p>
        <Icon className={`${styles.icon}`} name="CaretDown" />
      </button>
      <div
        className={`${styles.options} ${expanded && styles.optionsExpanded}`}
      >
        {items.map((item, key) => (
          <button
            role="option"
            aria-selected="false"
            onClick={(e) => onClickWrapper(e, item)}
            className={styles.button}
            key={key}
          >
            {item}
          </button>
        ))}
      </div>
    </div>
  );
}

export default Selector;
