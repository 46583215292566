import { OperationMSTypes } from '@spot-spotsat/types/definitions';
import getBbox from '@spot-spotsat/utils/geo/getBbox.util';
import { ChangeEvent, useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';

const LEAFLET_CONTROL_POSITION_CALSSES = {
  bottomLeft: 'leaflet-bottom leaflet-left',
  bottomRight: 'leaflet-bottom leaflet-right',
  topLeft: 'leaflet-top leaflet-left',
  topRight: 'leaflet-top leaflet-right',
};

type GlebasControlProperties = {
  glebes: OperationMSTypes.ById['glebes'];
};

export default function GlebasControl(props: GlebasControlProperties) {
  const { glebes }: GlebasControlProperties = props;

  const [selection, setSelection] = useState(0);

  const map = useMap();

  function onCheck(
    e: ChangeEvent<HTMLInputElement>,
    gleba: OperationMSTypes.ById['glebes'][number]
  ) {
    setSelection(Number(e.currentTarget.value));

    map.fitBounds(getBbox(gleba.geom));
    map.setMaxBounds(getBbox(gleba.geom));
  }

  useEffect(() => {
    map.fitBounds(getBbox(glebes[selection].geom));
    map.setMaxBounds(getBbox(glebes[selection].geom));
  }, [map, glebes, selection]);

  return (
    <div className={LEAFLET_CONTROL_POSITION_CALSSES.bottomRight}>
      <div className="leaflet-control leaflet-control-layers leaflet-control-layers-expanded">
        <div className="leaflet-control-layers-list">
          <div className="leaflet-control-layers-overlays">
            {glebes.map((glebe, index) => (
              <label
                key={index}
                htmlFor={`GlebaControl${index + 1}`}
                style={{ display: 'flex', gap: '4px' }}
              >
                <input
                  type="radio"
                  checked={selection === index}
                  value={index}
                  onChange={(e) => onCheck(e, glebe)}
                  name="GlebaControl"
                  id={`GlebaControl${index + 1}`}
                />
                Gleba {index + 1}
              </label>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
