import React from 'react';
import { ComponentPropsWithoutRef, MouseEventHandler, MouseEvent } from 'react';
import styles from './Button.module.css';

type ButtonProps = {
  label: string;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  button?: Omit<ComponentPropsWithoutRef<'button'>, 'onClick'>;
};

function Button(props: ButtonProps) {
  const { label, className, onClick, button } = props;

  function onClickWrapper(e: MouseEvent<HTMLButtonElement>) {
    onClick && onClick(e);
  }

  return (
    <button
      className={`${styles.button} ${className}`}
      onClick={onClickWrapper}
      {...button}
    >
      {label}
    </button>
  );
}

export default Button;
