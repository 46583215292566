import React from 'react';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styles from './NDVIChart.module.css';
import {
  CustomCSSProperties,
  OperationMSTypes,
} from '../../../types/definitions';
import GenericFallback from '../../boundaries/operations/GenericErrorBoundary';
import withErrorBoundary from '../../boundaries/operations/withErrorBoundary.hook';

type NDVIChartProps = {
  glebe: OperationMSTypes.ById['glebes'][number];
  planting: { start: string; end: string };
  crop: { start: string; end: string };
};

function NDVIChart(props: NDVIChartProps) {
  const { glebe, planting, crop }: NDVIChartProps = props;

  const ndvi = glebe.chart_ndvi;

  const dots = ndvi.data
    .filter((data) => {
      const dotNames = [
        'Plantio realizado',
        'Pico vegetativo',
        'Inicio de senescência',
        'Colhido',
      ];
      return dotNames.some((dot) => data.name.includes(dot));
    })
    .map((dot) => ({
      name: dot.name,
      x: new Date(dot.x[0]).getTime(),
      y: dot.y[0],
    }));

  const planting_end = new Date(planting.end);
  planting_end.setDate(planting_end.getDate() + 1);

  const planting_period = [
    { x: new Date(planting.start).getTime(), y: 1 },
    { x: planting_end.getTime(), y: 1 },
  ];

  const harvest_end = new Date(crop.end);
  harvest_end.setDate(harvest_end.getDate() - 1);

  const harvest_period = [
    { x: new Date(crop.start).getTime(), y: 1 },
    { x: harvest_end.getTime(), y: 1 },
  ];

  const vegetative_stage = ndvi.data.find(
    (dot) => dot.name === 'Indice Vegetativo'
  );
  const mapped_stage = vegetative_stage!.x.map((x, index) => ({
    x: new Date(x).getTime(),
    y: vegetative_stage!.y[index],
  }));

  const all_data_merged = mapped_stage
    .concat(planting_period)
    .concat(harvest_period);

  function CustomDots(props: any) {
    const { payload, cx, cy } = props;
    let color = '#000000';

    const match = dots.find((dot) => dot.x === payload.x);

    if (match?.name.includes('Plantio realizado')) {
      color = '#E21AAA';
    } else if (match?.name.includes('Pico vegetativo')) {
      color = '#00FF00';
    } else if (match?.name.includes('Inicio de senescência')) {
      color = '#DADA00';
    } else if (match?.name.includes('Colhido')) {
      color = '#FF0000';
    }

    if (!!match) {
      return (
        <circle
          cx={cx}
          cy={cy}
          r={5}
          fill="white"
          stroke={color}
          strokeWidth={4}
          fillOpacity={1}
        />
      );
    }

    return <></>;
  }

  function CustomXAxisLabel(props: any) {
    const { x, y, payload } = props;
    const date = new Date(payload.value).toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: 'short',
    });

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="black">
          {date}
        </text>
      </g>
    );
  }

  function CustomTooltip(props: any) {
    const { payload } = props;
    let { x, y } = !!payload[0] ? payload[0].payload : { x: 0, y: 0 };

    const date = new Date(x).toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });

    return (
      <div className={styles.tooltip}>
        <p className={styles.tooltipDate}>
          <b>Data:</b> {date}
        </p>
        <p className={styles.tooltipValue}>
          <b>Índice:</b> {y}
        </p>
      </div>
    );
  }

  function CustomLegend() {
    const planting_start = new Date(planting_period[0].x).toLocaleDateString(
      'pt-BR'
    );
    const planting_end = new Date(planting_period[1].x).toLocaleDateString(
      'pt-BR'
    );

    const harvest_start = new Date(harvest_period[0].x).toLocaleDateString(
      'pt-BR'
    );
    const harvest_end = new Date(harvest_period[1].x).toLocaleDateString(
      'pt-BR'
    );

    const planting = new Date('2021-07-24').toLocaleDateString('pt-BR');
    const peak = new Date('2021-08-18').toLocaleDateString('pt-BR');
    const senescence = new Date('2021-09-27').toLocaleDateString('pt-BR');
    const harvesting = new Date('2021-11-06').toLocaleDateString('pt-BR');

    return (
      <ul className={styles.legend}>
        <li
          style={{ '--color': '#00940F' } as CustomCSSProperties}
          data-type="line"
          className={styles.item}
        >
          Índice vegetativo
        </li>
        <li
          style={{ '--color': '#B3E0FF' } as CustomCSSProperties}
          data-type="line"
          className={styles.item}
        >
          Data indicada de plantio: {planting_start} à {planting_end}
        </li>
        <li
          style={{ '--color': '#FFD078' } as CustomCSSProperties}
          data-type="line"
          className={styles.item}
        >
          Data indicada de colheita: {harvest_start} à {harvest_end}
        </li>
        <li
          style={{ '--color': '#E21AAA' } as CustomCSSProperties}
          data-type="dot"
          className={styles.item}
        >
          Plantio realizado: {planting}
        </li>
        <li
          style={{ '--color': '#00FF00' } as CustomCSSProperties}
          data-type="dot"
          className={styles.item}
        >
          Pico vegetativo: {peak}
        </li>
        <li
          style={{ '--color': '#DADA00' } as CustomCSSProperties}
          data-type="dot"
          className={styles.item}
        >
          Início de senescência: {senescence}
        </li>
        <li
          style={{ '--color': '#FF0000' } as CustomCSSProperties}
          data-type="dot"
          className={styles.item}
        >
          Colhido: {harvesting}
        </li>
      </ul>
    );
  }

  return (
    <ResponsiveContainer
      width={'100%'}
      minWidth={800}
      height={'100%'}
      minHeight={400}
    >
      <ComposedChart data={all_data_merged}>
        <Tooltip content={<CustomTooltip />} />
        <Legend content={<CustomLegend />} />
        <CartesianGrid strokeDasharray="3 3" opacity={0.5} />
        <XAxis
          xAxisId={1}
          tick={<CustomXAxisLabel />}
          tickCount={20}
          dataKey={'x'}
          type="number"
          domain={[mapped_stage[0].x, mapped_stage[mapped_stage.length - 1].x]}
        />
        <XAxis
          xAxisId={2}
          type="number"
          dataKey={'x'}
          domain={[mapped_stage[0].x, mapped_stage[mapped_stage.length - 1].x]}
          hide
        />
        <YAxis dataKey={'y'} domain={[0, 1]} />
        <Area
          xAxisId={1}
          type={'monotone'}
          dataKey={'y'}
          stroke="#00940FFF"
          fill="#00940F22"
          data={mapped_stage}
        />
        <Line
          xAxisId={1}
          type={'monotone'}
          dataKey={'y'}
          stroke="#00000000"
          dot={<CustomDots />}
          data={mapped_stage}
        />
        <Area
          xAxisId={2}
          type={'monotone'}
          dataKey={'y'}
          stroke="#00000000"
          fill="#B3E0FFaa"
          data={planting_period}
        />
        <Area
          xAxisId={2}
          type={'monotone'}
          dataKey={'y'}
          stroke="#00000000"
          fill="#FFD078aa"
          data={harvest_period}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export default withErrorBoundary(NDVIChart, {
  fallback: GenericFallback,
  fallbackMessage: 'Dados NDVI indisponível no momento.',
});
