// import "leaflet/dist/leaflet.css";
import React, { useEffect, useState } from 'react';
import AreaPolygon from '../layers/AreaPolygon';
import styles from './PolygonMap.module.css';
import { MapContainer, TileLayer, useMap, ZoomControl } from 'react-leaflet';
import { Operation } from '../Polygons.page';
import { LatLngExpression } from 'leaflet';
import toFeatureCollection from '@spot-spotsat/utils/geo/toFeatureCollection.util';
import getBbox from '@spot-spotsat/utils/geo/getBbox.util';

interface PolygonMapProps {
  geoFilter: any;
  setGeoFilter: (value: any) => void;
  dataPolygon: any;
  selectedGleba: Operation | null;
  setSelectedDataGlebe: (value: any) => void;
  setDataFilter: (value: any) => void;
  tileLayerNDVI: any;
  tileLayerRGB: any;
  setTileLayerNDVI: (value: any) => void;
  setTileLayerRGB: (value: any) => void;
}

function PolygonMap({
  geoFilter,
  setGeoFilter,
  dataPolygon,
  selectedGleba,
  setSelectedDataGlebe,
  setDataFilter,
  tileLayerNDVI,
  tileLayerRGB,
  setTileLayerNDVI,
  setTileLayerRGB,
}: PolygonMapProps): JSX.Element {
  const getGeoFilter = () => geoFilter;

  const defaultCenter: LatLngExpression = [
    -15.15739914334851, -45.820448444570786,
  ];

  const [center, setCenter] = useState<LatLngExpression>(
    selectedGleba?.centroid || defaultCenter
  );

  function SetViewOnCoords({ coords }: { coords: LatLngExpression }) {
    const map = useMap();

    useEffect(() => {
      if (coords && selectedGleba) {
        map.setView(coords, 16);
      }
    }, [map, coords]);

    return null;
  }

  let geometry: GeoJSON.FeatureCollection = toFeatureCollection([]);

  if (dataPolygon !== undefined) {
    geometry = toFeatureCollection(
      dataPolygon.glebes.map((glebe) => glebe.geom)
    );
  }

  return (
    <>
      <MapContainer
        center={center}
        zoom={11}
        minZoom={14}
        scrollWheelZoom={true}
        className={styles.leafletContainer}
        zoomControl={true}
        maxBounds={getBbox(geometry)}
      >
        <ZoomControl position="topright" />
        <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
        {tileLayerNDVI && tileLayerNDVI !== null && (
          <TileLayer url={tileLayerNDVI} />
        )}
        {tileLayerRGB && tileLayerRGB !== null && (
          <TileLayer url={tileLayerRGB} />
        )}
        {selectedGleba && (
          <AreaPolygon
            data={dataPolygon}
            getGeoFilter={getGeoFilter}
            setGeoFilter={setGeoFilter}
            setSelectedDataGlebe={setSelectedDataGlebe}
            setDataFilter={setDataFilter}
            setTileLayerNDVI={setTileLayerNDVI}
            setTileLayerRGB={setTileLayerRGB}
            tileLayerNDVI={tileLayerNDVI}
            tileLayerRGB={tileLayerRGB}
          />
        )}

        <SetViewOnCoords coords={center} />
      </MapContainer>
    </>
  );
}

export default PolygonMap;
