import React, { useRef } from 'react';
import SidebarLinks from '../SidebarLinks/SidebarLinks.component';
import SidebarProfile from '../SidebarProfile/SidebarProfile.component';
import styles from './Sidebar.module.css';
import { useEffect, useState } from 'react';
import cls from '../../../utils/cls.util';
import Icon from '../../../components/Icon/Icon.component';
import getWindowDimensions, {
  WindowDimensions,
} from '../../../utils/getWindowDimensions.util';
import { useLocation, useNavigate } from 'react-router-dom';
import useStateSelector from '../../../store/hooks/useStateSelector.hook';
import Cookies from 'js-cookie';
import AuthMS from '../../../services/ms/AuthMS.service';
import useActionsDispatcher from '../../../store/hooks/useActionsDispatcher.hook';
import useActionsSelector from '../../../store/hooks/useActionsSelector.hook';

type SidebarStates = 'open' | 'collapsed' | 'overlay' | 'hidden';

function Sidebar(): JSX.Element {
  const { width }: WindowDimensions = getWindowDimensions();
  const isDesktop = width >= 1200;
  const isNotebooks = width < 1200 && width >= 768;
  const isMobile = width < 768;
  const initialState: SidebarStates = isDesktop
    ? 'open'
    : isNotebooks
    ? 'collapsed'
    : 'hidden';

  const navigate = useNavigate();
  const location = useLocation();
  const dispatcher = useActionsDispatcher();
  const { findUser } = useActionsSelector('auth');
  const { value } = useStateSelector('auth');

  const [selected, setSelected] = useState<string | undefined>(
    value.user?.active.profile_name || undefined
  );
  const [expanded, setExpanded] = useState<boolean>(false);
  const [whichState, setWhichState] = useState<SidebarStates>(initialState);

  const optionsRef = useRef<HTMLDivElement>(null);

  const sidebarStates: Record<SidebarStates, string> = {
    open: styles.open,
    collapsed: styles.collapsed,
    overlay: styles.overlay,
    hidden: styles.hidden,
  };

  async function onSelectOption(
    e: React.MouseEvent<HTMLButtonElement>,
    item: string
  ) {
    setSelected(item);
    setExpanded(false);

    const response = await AuthMS.ChangeActiveProfile({
      profile_id: e.currentTarget.value,
    });

    if (!response) return;

    Cookies.set(process.env.REACT_APP_COOKIE_KEY_TOKEN!, response.token, {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });

    dispatcher(findUser(response.token));

    window.location.reload();
  }

  function handleToggle(
    e: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ): void {
    e.preventDefault();

    switch (whichState) {
      case 'open':
        setWhichState('collapsed');
        break;
      case 'collapsed':
        setWhichState('open');
        break;
      case 'overlay':
        setWhichState('hidden');
        break;
      case 'hidden':
        setWhichState('overlay');
        break;
    }
  }

  function onOutsideClick(e: globalThis.MouseEvent) {
    if (optionsRef.current && !optionsRef.current.contains(e.target as Node)) {
      setExpanded(false);
    }
  }

  useEffect(() => {
    function closeSidebar() {
      if (isMobile) {
        setWhichState('hidden');
      } else if (isNotebooks) {
        setWhichState('collapsed');
      }
    }

    closeSidebar();

    document.addEventListener('mousedown', (e: globalThis.MouseEvent) =>
      onOutsideClick(e)
    );
    return () => {
      document.removeEventListener('mousedown', (e: globalThis.MouseEvent) =>
        onOutsideClick(e)
      );
    };
  }, [location.pathname, isMobile, isNotebooks]);

  return (
    <aside data-testid="sidebar-wrapper" className={styles.container}>
      {['hidden', 'overlay'].includes(whichState) && (
        <header
          data-testid="mobile-navigation-header"
          className={styles.mobileHeader}
        >
          <button
            data-testid="mobile-sidebar-toggle"
            className={styles.toggle}
            onClick={(e) => handleToggle(e)}
          >
            <Icon className={styles.toggleIcon} name="CaretDoubleLeft" />
          </button>
        </header>
      )}
      <div
        data-testid="sidebar"
        className={cls(whichState, styles.aside, sidebarStates)}
      >
        <section
          data-testid="sidebar-header"
          data-collapsed={whichState === 'collapsed'}
          className={styles.header}
        >
          {whichState !== 'collapsed' && (
            <img
              className={styles.logo}
              src="/assets/logo.png"
              alt="Logomarca Spotview"
              onClick={() => navigate('/')}
            />
          )}
          <button
            data-testid="sidebar-toggle"
            className={styles.toggle}
            onClick={(e) => handleToggle(e)}
          >
            {whichState === 'collapsed' && (
              <Icon
                data-testid="toggle-icon-right"
                className={styles.toggleIcon}
                name="CaretDoubleRight"
              />
            )}
            {whichState !== 'collapsed' && (
              <Icon
                data-testid="toggle-icon-left"
                className={styles.toggleIcon}
                name="CaretDoubleLeft"
              />
            )}
          </button>
        </section>
        <hr />
        <SidebarProfile isCollapsed={whichState === 'collapsed'} />
        <div className={styles.wrapper} role="listbox">
          <button
            className={styles.input}
            onClick={() => setExpanded((prev) => !prev)}
            value={selected}
          >
            <p>{selected}</p>
            <Icon className={styles.icon} name="CaretDown" />
          </button>
          <div
            ref={optionsRef}
            className={`${styles.options} ${
              expanded && styles.optionsExpanded
            }`}
          >
            {value.user?.profiles.map((item, key) => {
              if (!item) return <React.Fragment key={key}></React.Fragment>;

              return (
                <button
                  role="option"
                  aria-selected={selected === item.description}
                  onClick={(e) => onSelectOption(e, item.description)}
                  className={styles.button}
                  key={key}
                  value={item.id}
                >
                  {item.description}
                </button>
              );
            })}
          </div>
        </div>
        <SidebarLinks isCollapsed={whichState === 'collapsed'} />
      </div>
    </aside>
  );
}

export default Sidebar;
