import React from 'react';
import iconClose from '../../../../../assets/close.svg';
import styles from './ActionResponseModal.module.css';

type ActionConfirmationProps = {
  title: string;
  subtitle?: string;
  forwardRef?: React.RefObject<HTMLDialogElement>;
  onClosed?: () => void;
};

export default function ActionResponseModal(props: ActionConfirmationProps) {
  const { forwardRef, onClosed, title, subtitle }: ActionConfirmationProps =
    props;

  function onCancel() {
    onClosed && onClosed();
    forwardRef?.current?.close();
  }

  return (
    <dialog ref={forwardRef}>
      <div className={styles.card}>
        <div className={styles.divIconClose}>
          <img
            src={iconClose}
            alt="close"
            className={styles.iconClose}
            onClick={onCancel}
          />
        </div>
        <div className={styles.divIconTitle}>
          <h4 className={styles.iconTitle}>{title}</h4>
        </div>
        {subtitle && <p className={styles.paragraph}>{subtitle}</p>}
      </div>
    </dialog>
  );
}
