import React, { useEffect, useRef, useState } from 'react';
import Button from '../../../../components/Button/Button.component';
import styles from './User.module.css';
import { Modal } from '../../components/Modal';
import { DataGrid } from '@mui/x-data-grid';
import Icon from '../../../../components/Icon/Icon.component';
import AccountMS from '../../../../services/ms/AccountMS.service';
import toLocaleDate from '../../../../utils/toLocaleDate.util';
import useStateSelector from '../../../../store/hooks/useStateSelector.hook';
import { useNavigate } from 'react-router-dom';

export default function User() {
  const { value: auth } = useStateSelector('auth');

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [reload, setReload] = useState<boolean>(false);

  const inviteUser = useRef<HTMLDialogElement>(null);
  const actionConfirmationModal = useRef<HTMLDialogElement>(null);
  const actionResponseModal = useRef<HTMLDialogElement>(null);
  const actionInviteResponseModal = useRef<HTMLDialogElement>(null);
  const isLoading = useRef<boolean>(true);

  function handleInviteUser() {
    inviteUser.current?.showModal();
  }

  const columns = [
    { field: 'name', headerName: 'Nome', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 2 },
    {
      field: 'last_login',
      headerName: 'Último Login',
      flex: 1,
      renderCell: (params: any) => toLocaleDate(params.value, true),
    },
    {
      field: 'id',
      headerName: 'Ações',
      flex: 0,
      renderCell: (params: any) => (
        <div className={styles.actions}>
          <button
            onClick={() =>
              navigate('/settings?tab=1', { state: { user: params.row } })
            }
          >
            <Icon name="Pen" />
          </button>
          {auth.user?.id !== params.value && (
            <button onClick={() => onRemoveCall(params.value)}>
              <Icon name="Trash" />
            </button>
          )}
        </div>
      ),
    },
  ];

  function onRemoveCall(id: string) {
    if (!actionConfirmationModal.current) return;

    actionConfirmationModal.current.dataset.value = id;
    actionConfirmationModal.current.showModal();
  }

  async function onRemoveUser(value: any) {
    if (!actionConfirmationModal.current) return;
    actionConfirmationModal.current.close();

    await AccountMS.Delete(value);

    if (!actionResponseModal.current) return;

    actionResponseModal.current.showModal();

    setReload(!reload);
  }

  function onInviteSent() {
    if (!inviteUser.current) return;
    inviteUser.current.close();

    if (!actionInviteResponseModal.current) return;
    actionInviteResponseModal.current.showModal();
  }

  useEffect(() => {
    async function fetchData() {
      const responses = await Promise.allSettled([AccountMS.All()]);

      responses.forEach((response, index) => {
        if (response.status !== 'fulfilled') {
          return;
        }
        if (response.value === false) {
          return;
        }

        if (index === 0) {
          setData((response.value as any).results);
        }
      });

      isLoading.current = false;
    }

    fetchData();
  }, [reload]);

  return (
    <div className={styles.container} role="dialog">
      <div className={styles.headerTitle}>
        <h4 className={styles.title}>Usuários cadastrados</h4>
        <Button
          label="Convidar Usuário"
          className={styles.button}
          onClick={handleInviteUser}
        />
      </div>
      <div className={styles.divTable}>
        <DataGrid
          loading={isLoading.current}
          initialState={{ pagination: { paginationModel: { pageSize: 25 } } }}
          pageSizeOptions={[25]}
          columns={columns}
          rows={data}
          sx={{ '& .MuiDataGrid-main': { width: 0, minWidth: '100%' } }}
          disableColumnResize
          disableColumnMenu
          autoHeight
        />
      </div>
      <Modal.InviteUser forwardRef={inviteUser} onSuccess={onInviteSent} />

      <Modal.ActionConfirmation
        forwardRef={actionConfirmationModal}
        title="Tem certeza que deseja excluir este usuário?"
        subtitle="Esta ação não pode ser desfeita."
        onConfirmed={onRemoveUser}
      />
      <Modal.ActionResponse
        forwardRef={actionResponseModal}
        title="Usuário excluído com sucesso!"
      />
      <Modal.ActionResponse
        forwardRef={actionInviteResponseModal}
        title="Usuários convidados com sucesso!"
      />
    </div>
  );
}
