import React from 'react';
import { useState } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import styles from './ChartWeather.module.css';
import { OperationMSTypes } from '../../../types/definitions';
import GenericFallback from '../../boundaries/operations/GenericErrorBoundary';
import withErrorBoundary from '../../boundaries/operations/withErrorBoundary.hook';

type WeatherDataType = {
  name: string;
  date: string;
  dateLabel: string;
  precipitacao: number;
  temperatura: number;
  tempo: number;
};

const normalizeWeatherConstant: Record<number, string> = {
  0: 'Claro',
  1: 'Tempo bom',
  2: 'Parcialmente nublado',
  3: 'Nublado',
  45: 'Garoa densa',
  48: 'Garoa densa',
  51: 'Garoa leve',
  53: 'Garoa moderada',
  55: 'Garoa densa',
  56: 'Garoa densa',
  57: 'Garoa densa',
  61: 'Chuva leve',
  63: 'Chuva moderada',
  65: 'Chuva pesada',
  66: 'Garoa densa',
  67: 'Garoa densa',
  71: 'Neve leve',
  73: 'Neve moderada',
  75: 'Neve pesada',
  77: 'Grãos de neve',
  80: 'Chuva leve',
  81: 'Chuva moderada',
  82: 'Chuva pesada',
  85: 'Neve leve',
  86: 'Neve pesada',
  95: 'Trovoada leve ou moderada',
  96: 'Trovoada com granizo leve',
  99: 'Trovoada com granizo pesado',
};

interface CustomTooltipProps {
  active?: boolean;
  payload?: any;
}

const CustomTooltip = ({ active, payload }: CustomTooltipProps) => {
  if (!active || !payload || !payload.length) return null;

  const { date, tempo, precipitacao, temperatura } = payload[0].payload;

  const formattedDate = date.split('-').reverse().join('/');

  return (
    <div className={styles.containerTooltip}>
      <p>
        Data: <b>{formattedDate}</b>
      </p>
      <p>
        Tempo: <b>{normalizeWeatherConstant[tempo]}</b>
      </p>
      <p style={{ color: '#053A65' }}>
        Precipitação (mm): <b>{precipitacao}</b>
      </p>
      <p style={{ color: '#FF0E0E' }}>
        Temperatura (°C): <b>{temperatura}</b>
      </p>
    </div>
  );
};

type ChartWeatherProps = {
  data?: OperationMSTypes.ById['glebes'][number]['weather'];
};

function ChartWeather(props: ChartWeatherProps) {
  const { data }: ChartWeatherProps = props;

  const [filterBy, setFilterBy] = useState<string>('');

  if (!data) return <>Dados de clima indisponíveis.</>;

  const formattedWeatherData: WeatherDataType[] = data.daily.time.map(
    (date, index) => ({
      name: date,
      date: date,
      dateLabel: date.split('-').reverse().join('/'),
      precipitacao: data.daily.precipitation_sum[index] ?? 0,
      temperatura: data.daily.temperature_2m_mean[index] ?? 0,
      tempo: data.daily.weathercode[index] ?? 0,
    })
  );

  return (
    <div className={styles.containerRecharts}>
      {/* <div className={styles.divFilter}>
                <label>Filtrar por:</label>
                <select className={styles.select} value={filterBy} onChange={(e) => setFilterBy(e.target.value)}>
                    <option value="">Todos</option>
                    {uniqWeather.map((uw) => (
                        <option value={String(uw)} key={uw}>{normalizeWeatherConstant[uw]}</option>
                    ))}
                </select>
            </div> */}
      <div className={styles.divRecharts}>
        <ResponsiveContainer
          width="100%"
          height="100%"
          style={{ fontSize: '12px' }}
        >
          <AreaChart
            data={formattedWeatherData.filter((df) =>
              !filterBy ? true : String(df.tempo) === filterBy
            )}
          >
            <Tooltip content={<CustomTooltip />} />
            <CartesianGrid strokeDasharray="3 5" vertical={false} />
            <XAxis
              dataKey="dateLabel"
              interval={40}
              style={{ fontSize: '12px' }}
            />
            <YAxis dataKey={'temperatura'} yAxisId="left" />
            <YAxis
              dataKey={'precipitacao'}
              yAxisId="right"
              orientation="right"
            />
            <Area
              type="monotone"
              yAxisId="left"
              dataKey="temperatura"
              name="Temperatura"
              stroke="#FF0E0E"
              fill="#FF0E0E33"
            />
            <Area
              type="monotone"
              yAxisId="right"
              dataKey="precipitacao"
              name="Precipitação"
              stroke="#1F8EF1"
              fill="#1F8EF133"
            />
          </AreaChart>
        </ResponsiveContainer>
        <div>
          <p className={styles.legendTemp}>Temperatura (°C)</p>
          <p className={styles.legendPrec}>Precipitação (mm)</p>
        </div>
      </div>
    </div>
  );
}

export default withErrorBoundary(ChartWeather, {
  fallback: GenericFallback,
  fallbackMessage:
    'Não há dados de clima suficientes para gerar o gráfico de clima',
});
